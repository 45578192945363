import { React } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { login } from "../../features/authentication";
import { setContext } from "../../features/context";

import config from "../../config";
import { switchCommunity } from "../../lib/community";
import { getContext } from "../../lib/signin";
import encryption from "../../lib/encryption";
import navGrid from "../../lib/thumbGrid";

const CommunityHub = ({ language, signout, main_focus }) => {
  const communities = useSelector((state) => state.communities.value);
  const auth = useSelector((state) => state.authentication.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SwitchCommunities = async (cid) => {
    if (cid) {
      let switcher = await switchCommunity(cid);
      if (switcher === "refresh") {
        //token has been refreshed, try again
        SwitchCommunities(cid);
      } else if (switcher === "error") {
        //refresh token expired or unknown error
        signout();
      } else {
        //set tag, client_id and last_update
        localStorage.setItem("last_updated", Date.now());
        const hash = encryption.encrypt(switcher.jwt);
        localStorage.setItem("tag", hash);

        //auth
        let loginState = {
          ...auth,
          community_id: switcher.community_id,
          role: switcher.role,
          level: config.levels.indexOf(switcher.role),
        };
        localStorage.setItem("auth", JSON.stringify(loginState));
        dispatch(login(loginState));

        //context
        const context = await getContext(
          switcher.community_id,
          switcher.role,
          communities
        );
        localStorage.setItem("context", JSON.stringify(context));
        console.log("CONTEXT", context);
        dispatch(setContext(context));

        navigate("/mycommunity");
      }
    }
  };

  ////////////// KEYBOARD INTERACTION //////////////////
  const keyAction = (e) => {
    //navigated grid
    if (e.target.tagName.toUpperCase() === "DIV") {
      //detect ctrl+key
      let key;
      if (e.ctrlKey || e.metaKey) {
        key = "CTRL+" + e.key;
      } else {
        key = e.key;
      }
      const _key = config.thumb_grid_keys.indexOf(key);
      if (_key >= 0) {
        let container = document.getElementById("thumb_wall");
        let contents = container.querySelectorAll("div[tabindex]");
        navGrid(container, contents, key);
      }
    }
  };

  ////////////// RENDER GUI //////////////////
  const communityList = communities.map((data, idx) => (
    <div
      role="figure"
      aria-label={data.title}
      className="thumb-group"
      key={idx}
      title={data.title}
      onKeyDown={(e) => keyAction(e)}
      tabIndex="0"
    >
      <div
        className="thumb-group-content thumb-hover"
        onClick={() => {
          SwitchCommunities(data.id);
        }}
      >
        <div
          role="img"
          className="thumb-block"
          aria-label={language.labels.aria.logo.replace(/{org}/g, data.title)}
          style={{
            marginBottom: "3px",
            backgroundImage: `url(${
              config.server.storage.bucket + data.crest
            })`,
          }}
        ></div>
        <button
          className="name"
          id={data.id}
          style={{ width: "100%", padding: "0 1em" }}
          onClick={() => {
            SwitchCommunities(data.id);
          }}
        >
          {data.uname}
        </button>
        <div className="handle2" style={{ width: "100%", padding: "0 1em" }}>
          {data.title}
        </div>
        <div>{language.labels.roles[data.role]}</div>
      </div>
    </div>
  ));

  return (
    <main className="main-page" ref={main_focus} tabIndex="0">
      <div className="page-section mobile-justify" style={{ display: "flex" }}>
        <h1 className="heading">
          {language.labels.communities.my_communities.replace(
            /{n}/g,
            communities.length
          )}
        </h1>
      </div>

      {communities.length > 0 && (
        <div className="page-section" style={{ paddingBottom: "2em" }}>
          <section
            id="thumb_wall"
            className="thumb-group-container mobile-justify"
            aria-label={language.labels.aria.grid}
          >
            {communityList}
          </section>
        </div>
      )}
    </main>
  );
};

export default CommunityHub;
