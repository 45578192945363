import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Modal from "../modal/Modal";
import config from "../../config";
import axiosCall from "../../lib/axios";

const Topics = ({ language, main_focus, signout }) => {
  //initialize
  const navigate = useNavigate();
  const community = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);

  const [theme, setTheme] = useState("");
  const [themeErr, setThemeErr] = useState("");
  const [topics, setTopics] = useState([]);
  const [aiModal, setAiModal] = useState(false);

  const abortAxios = useRef();

  const abort = () => {
    abortAxios.current = 0;
    setAiModal(false);
  };

  const suggestTopics = async (pathway) => {
    // unabort axios call
    const timestamp = Date.now();
    abortAxios.current = timestamp;

    // try and submit data
    setAiModal(true);
    let result = await axiosCall("pathway/suggest", { pathway }, false);

    //process result if call not aborted
    if (timestamp === abortAxios.current) {
      if (result.success) {
        setAiModal(false);
        if (
          result.data.error ||
          result.status === 201 ||
          !result.data.pathways
        ) {
          //unknown error with AI
          setThemeErr(language.labels.error.unknown_later);
        } else if (result.data.pathways.length > 0) {
          //ok
          setTopics(result.data.pathways);
        } else {
          //exceeded token limit or gibberish input
          setThemeErr(language.labels.bites.error.topic_vague);
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        suggestTopics(pathway);
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  const checkTheme = () => {
    setThemeErr("");
    setTopics([]);
    if (theme.trim() === "") {
      setThemeErr(language.labels.bites.error.theme);
    } else {
      suggestTopics(theme.trim());
    }
  };

  const savePathways = async () => {
    let result = await axiosCall("pathways/create", {
      pathways: topics,
    });
    if (result.success) {
      if (result.data.message) {
        //unknown error with AI
        setThemeErr(language.labels.error.unknown_later);
      } else {
        //success
        navigate("/pathways");
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      savePathways();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const modifySuggestions = (e) => {
    let tmp = [...topics];
    tmp[e.getAttribute("index")] = e.value;
    setTopics(tmp);
  };

  const removeSuggestion = (e) => {
    let tmp = [...topics];
    tmp.splice(e.getAttribute("index"), 1);
    setTopics(tmp);
  };

  const _topics = topics.map((topic, index) => (
    <div style={{ display: "flex", gap: "0.5em" }} key={index}>
      <input
        style={{ flex: "1", fontSize: "0.9em", padding: "4px 8px" }}
        value={topic.slice(0, config.string.title)}
        maxLength={config.string.title}
        index={index}
        onChange={(e) => {
          modifySuggestions(e.target);
        }}
      />
      <button
        style={{ fontSize: "0.75em" }}
        className="glyphs link-delete"
        index={index}
        aria-label={language.labels.app.remove}
        onClick={(e) => {
          removeSuggestion(e.target);
        }}
      >
        x
      </button>
    </div>
  ));

  if (auth.level < 2) {
    return <></>;
  } else {
    return (
      <>
        <main className="main-page" ref={main_focus} tabIndex="0">
          <div className="page-section" style={{ maxWidth: "44rem" }}>
            <h1
              className="heading2"
              style={{ fontWeight: "bold", fontSize: "1.1em" }}
            >
              {language.labels.ai.help}
            </h1>

            <div
              style={{ marginTop: "0.5em" }}
              dangerouslySetInnerHTML={{ __html: language.labels.tips.bites }}
            />

            <div
              style={{
                display: "flex",
                marginTop: "1em",
                alignItems: "center",
                gap: "0.5em",
              }}
            >
              <input
                style={{ width: "100%" }}
                placeholder={language.labels.tips.enter_theme}
                aria-placeholder={language.labels.tips.enter_theme}
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
                onKeyUpCapture={(e) => e.key === "Enter" && checkTheme()}
                maxLength={config.string.title}
              />
              <button
                className="button"
                style={{ fontSize: "0.9em", padding: "0.46em 0.5em" }}
                onClick={() => checkTheme()}
              >
                {language.labels.ai.list}
              </button>
            </div>
            {themeErr && (
              <div className="errtext" role="alert">
                {themeErr}
              </div>
            )}

            {topics.length > 0 && (
              <div>
                <h2
                  className="heading2"
                  style={{ fontWeight: "500", margin: "1.5em 0.25em 0.5em" }}
                >
                  {language.labels.ai.suggestions.pathways}
                </h2>
                <div style={{ whiteSpace: "pre-wrap" }}>{_topics}</div>
                <button
                  className="button"
                  style={{ marginTop: "1.5em" }}
                  onClick={() => savePathways()}
                >
                  {language.labels.ai.save_pathways}
                </button>
              </div>
            )}

            {/* back */}
            <button
              style={{ alignSelf: "flex-start", marginTop: "2em" }}
              className="link"
              onClick={() => {
                navigate("/newpathway");
              }}
            >
              {language.labels.ai.return_add}
            </button>
          </div>
        </main>

        <Modal className="modal-dynamic" show={aiModal}>
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">{language.labels.loader.wait}</h2>
            <div style={{ fontSize: "1.1em", padding: "0.5rem" }}>
              {language.labels.ai.moment}
            </div>
            <div
              className="dotdotdot"
              style={{ fontSize: "1.1em", padding: "0.5rem" }}
            >
              {language.labels.ai.generating_pathways}
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "1em" }}>
            <button className="button-off" onClick={() => abort()}>
              {language.labels.ai.cancel}
            </button>
          </div>
        </Modal>
      </>
    );
  }
};

export default Topics;
