import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import { setCommunities } from "../../../features/communities";
import { setContext } from "../../../features/context";

import axiosCall from "../../../lib/axios";

const CommunityEdit = ({ language, setEditCommunity, auth, signout }) => {
  ///////////////// INITIALIZE /////////////////
  const community = useSelector((state) => state.context.value);
  const communities = useSelector((state) => state.communities.value);
  const dispatch = useDispatch();

  const [communityType, setCommunityType] = useState(community.type);
  const [verified, setVerified] = useState(community.verified || false);
  const [enterprizeCap, setEnterprizeCap] = useState(
    community.enterprize_cap || 0
  );
  const [remittance, setRemittance] = useState(community.remittance || false);

  const [ex_video, setEx_video] = useState(community.ex_video || false);
  const [ex_knowledge, setEx_knowledge] = useState(
    community.ex_knowledge || false
  );

  ///////////////// FUNCTIONS /////////////////
  async function resetCommunities(obj) {
    let _communities = [...communities];
    for (var i = 0; i < _communities.length; i++) {
      if (_communities[i].id === community.id) {
        _communities[i] = obj;
        return _communities;
      }
    }
  }

  const applyChanges = async () => {
    let data = { cid: community.id };

    let dirty = false;

    if (community.type !== communityType) {
      dirty = true;
      data.type = communityType;
    }

    let cap = Math.floor(enterprizeCap);
    if (cap < 0) cap = 0;
    setEnterprizeCap(cap);
    if (community.enterprize_cap !== cap) {
      dirty = true;
      data.enterprize_cap = cap;
    }

    if ((community.verified || false) !== verified) {
      dirty = true;
      data.verified = verified;
    }

    if ((community.remittance || false) !== remittance) {
      dirty = true;
      data.remittance = remittance;
    }

    if ((community.ex_video || false) !== ex_video) {
      dirty = true;
      data.ex_video = ex_video;
    }

    if ((community.ex_knowledge || false) !== ex_knowledge) {
      dirty = true;
      data.ex_knowledge = ex_knowledge;
    }

    if (dirty) {
      let result = await axiosCall("admin/community/config", data);
      console.log(result);
      if (result.success) {
        let _community = { ...community };
        if (data.type) {
          _community.type = data.type;
        }
        if (data.enterprize_cap !== undefined) {
          _community.enterprize_cap = data.enterprize_cap;
        }
        if (data.verified !== undefined) {
          _community.verified = data.verified;
        }
        if (data.remittance !== undefined) {
          _community.remittance = data.remittance;
        }

        if (data.ex_video !== undefined) {
          _community.ex_video = data.ex_video;
        }
        if (data.ex_knowledge !== undefined) {
          _community.ex_knowledge = data.ex_knowledge;
        }

        // set context
        dispatch(setContext(_community));
        localStorage.setItem("context", JSON.stringify(_community));
        // set communities
        const _communities = await resetCommunities(_community);
        dispatch(setCommunities(_communities));
        localStorage.setItem("communities", JSON.stringify(_communities));
        setEditCommunity(false);
      } else if (result.refresh) {
        // token has been refreshed, try again
        applyChanges();
      } else {
        // refresh token expired or unknown error
        signout();
      }
    }
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <div className="page-section mobile" style={{ marginTop: "0.5em" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">{auth.level === 5 ? "s" : "|"}</div>
          <div className="subheading" style={{ overflow: "initial" }}>
            {auth.level === 5
              ? language.labels.app.config
              : language.labels.app.settings}
          </div>
        </div>
      </div>

      {auth.level === 5 && (
        <>
          {/* community type */}
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <div>{language.labels.communities.community_type}</div>
            <select
              onChange={(e) => {
                setCommunityType(e.target.value);
              }}
              value={communityType}
              autoFocus
            >
              <option
                value="corporate"
                selected={community.type === "corporate"}
              >
                corporate
              </option>

              <option value="customer" selected={community.type === "customer"}>
                customer
              </option>

              <option
                value="institution"
                selected={community.type === "institution"}
              >
                institution
              </option>

              <option value="public" selected={community.type === "public"}>
                public
              </option>
            </select>
          </div>

          {/* enterprize cap */}
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <div>Enterprise Cap</div>
            <input
              id="verified_selector"
              value={enterprizeCap}
              type="number"
              min="0"
              step="1"
              onChange={(e) => {
                setEnterprizeCap(e.target.value);
              }}
              style={{ width: "6em" }}
            />
          </div>
          <div className="quote-text" style={{ marginTop: "0.2em" }}>
            Number of complimentary (enterprise) accounts granted to community
            personnel. Default 0.
          </div>

          {/* verified */}
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <input
              id="verified_selector"
              value={verified}
              type="checkbox"
              checked={verified}
              onChange={() => {
                setVerified(!verified);
              }}
            />
            <label htmlFor="verified_selector">Verified</label>
          </div>

          {/* remittance */}
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <input
              id="remittance_selector"
              value={remittance}
              type="checkbox"
              checked={remittance}
              onChange={() => {
                setRemittance(!remittance);
              }}
            />
            <label htmlFor="remittance_selector">Remittance</label>
          </div>
          <div className="quote-text" style={{ marginTop: "0.2em" }}>
            Remittance only applicable to public communities
          </div>
        </>
      )}

      {/* ex_video */}
      <div
        style={{
          marginTop: "1.5em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <input
          id="ex_k_selector"
          value={ex_video}
          type="checkbox"
          checked={ex_video}
          onChange={() => {
            setEx_video(!ex_video);
          }}
        />
        <label htmlFor="ex_k_selector">
          {language.labels.communities.settings.ex_video}
        </label>
      </div>

      {/* ex_knowledge */}
      <div
        style={{
          marginTop: "1.5em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <input
          id="ex_k_selector"
          value={ex_knowledge}
          type="checkbox"
          checked={ex_knowledge}
          onChange={() => {
            setEx_knowledge(!ex_knowledge);
          }}
        />
        <label htmlFor="ex_k_selector">
          {language.labels.communities.settings.ex_knowledge}
        </label>
      </div>

      {/* buttons */}
      <div style={{ display: "flex", gap: "0.25em", margin: "3em 0" }}>
        <button
          className="button-secondary"
          onClick={() => setEditCommunity(false)}
        >
          {language.labels.app.cancel}
        </button>
        <button className="button" onClick={applyChanges}>
          {language.labels.app.apply}
        </button>
      </div>
    </div>
  );
};

export default CommunityEdit;
