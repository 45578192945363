import React, { useState } from "react";
import config from "../../../config";
import navGrid from "../../../lib/thumbGrid";
import { naturalDate } from "../../../lib/date";
import { VideoEditor, VideoUploader } from "../../widgets";

const BlockVideos = ({
  community,
  level,
  language,
  bid,
  blockVideos,
  videoState,
  setVideoState,
  getVideos,
  signout,
}) => {
  //////////// INITIALIZE ///////////
  const [currentVideo, setCurrentVideo] = useState({});

  //////////// FUNCTIONS ///////////
  const pickVid = (elem) => {
    setCurrentVideo(JSON.parse(elem.getAttribute("video")));
    setVideoState("edit");
  };

  const closeVid = () => {
    setCurrentVideo({});
    setVideoState("list");
  };

  const onSuccess = () => {
    getVideos(true);
  };

  const onCancel = () => {
    setVideoState("list");
  };

  const changeVideDesc = (desc) => {
    getVideos();
    setCurrentVideo({ ...currentVideo, description: desc });
  };

  const deleteVideo = () => {
    getVideos(true);
  };

  //////////// USER INTERACTIONS ///////////

  const vidClickAction = (e) => {
    pickVid(e.target.parentNode);
  };

  const vidKeyAction = (e) => {
    // selected button link
    if (e.target.tagName.toUpperCase() === "SPAN") {
      e.stopPropagation();
      if (e.key === "Enter" || e.key === " ") {
        //select video
        pickVid(e.target);
      }
    }

    //navigated grid
    if (e.target.tagName.toUpperCase() === "DIV") {
      //prevent default scroll but allow Tab
      if (e.key !== "Tab") e.preventDefault();

      //detect ctrl+key
      let key;
      if (e.ctrlKey || e.metaKey) {
        key = "CTRL+" + e.key;
      } else {
        key = e.key;
      }
      const _key = config.thumb_grid_keys.indexOf(key);
      if (_key >= 0) {
        let container = document.getElementById("thumb_wall");
        let contents = container.querySelectorAll("div[tabindex]");
        navGrid(container, contents, key, 154);
      }
    }
  };

  //////////// RENDER GUI ///////////

  const BlockVideos = blockVideos.map((bv, idx) => (
    <div
      role="figure"
      aria-label={language.labels.aria.video_preview}
      className="thumb-group vid"
      key={idx}
      onKeyDown={(e) => vidKeyAction(e)}
      tabIndex="0"
    >
      {bv.status === "processing" ? (
        <span
          role="button"
          aria-label={language.labels.aria.video_select}
          className="thumb-vid unavailable"
          style={{ cursor: "pointer" }}
          video={JSON.stringify(bv)}
          onKeyDown={(e) => vidKeyAction(e)}
          onClick={(e) => vidClickAction(e)}
          tabIndex="0"
        >
          <div style={{ fontWeight: "500" }}>
            {language.labels.videos.uploading.processing}
          </div>
          <div style={{ marginTop: "1em", fontSize: "0.8em" }}>
            {language.labels.videos.uploaded.replace(
              /{time}/g,
              naturalDate(bv.created, language.locale, language.labels.date)
            )}
          </div>
        </span>
      ) : (
        <span
          role="button"
          aria-label={language.labels.aria.video_select}
          className="thumb-vid"
          style={{ cursor: "pointer" }}
          video={JSON.stringify(bv)}
          onKeyDown={(e) => vidKeyAction(e)}
          onClick={(e) => vidClickAction(e)}
          tabIndex="0"
        >
          <video
            style={{
              width: `${3 * bv.aspect < 4 ? "150px" : 200 * bv.aspect + "px"}`,
              marginTop: `${
                3 * bv.aspect < 4 ? 75 * bv.aspect - 75 + "px" : "0"
              }`,
              marginLeft: `${
                3 * bv.aspect < 4 ? "0" : 100 - 100 * bv.aspect + "px"
              }`,
            }}
            muted
            loop
            autoPlay
            src={config.server.storage.cache + bv.preview}
          ></video>
        </span>
      )}
    </div>
  ));

  return (
    <div
      role="tabpanel"
      style={{ paddingBottom: "2em" }}
      aria-label={language.labels.navigation.videos}
      tabIndex={0}
    >
      {videoState === "list" && (
        <>
          {/* add video */}
          {level > 0 && (
            <div
              style={{ display: "flex", gap: "0.5em", alignItems: "center" }}
            >
              <button
                className="button-glyph"
                onClick={() => {
                  setVideoState("new");
                }}
              >
                +
              </button>
              <div>
                <span
                  className="link"
                  onClick={() => {
                    setVideoState("new");
                  }}
                >
                  {language.labels.videos.add}
                </span>
              </div>
            </div>
          )}

          <div style={{ margin: "1em 0 0.5em 0", display: "flex", gap: "1em" }}>
            <div>
              {blockVideos.length < 1
                ? language.labels.videos.none_bite
                : `${
                    blockVideos.length > 1
                      ? language.labels.videos.multiple.replace(
                          /{n}/g,
                          blockVideos.length
                        )
                      : language.labels.videos.one
                  }`}
            </div>

            {/* refresh */}
            {level > 0 && (
              <button
                aria-label={language.labels.aria.refresh_videos}
                className="glyphs success"
                onClick={() => getVideos(true)}
              >
                r
              </button>
            )}
          </div>
          <section
            id="thumb_wall"
            className="thumb-group-container"
            aria-label={language.labels.aria.grid}
          >
            {BlockVideos}
          </section>
        </>
      )}
      {videoState === "new" && (
        <VideoUploader
          bid={bid}
          language={language}
          signout={signout}
          onCancel={onCancel}
          onSuccess={onSuccess}
          cancelLabel={language.labels.app.cancel}
          hintLabel={language.labels.videos.uploading.hint}
        ></VideoUploader>
      )}
      {videoState === "edit" && (
        <VideoEditor
          community={community}
          style={{ maxWidth: "44rem" }}
          language={language}
          currentVideo={currentVideo}
          onDelete={deleteVideo}
          onModify={changeVideDesc}
          onClose={closeVid}
          bite={true}
          signout={signout}
        ></VideoEditor>
      )}
    </div>
  );
};

export default BlockVideos;
