import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import { setBasic } from "../../features/basic";

import config from "../../config";

import { Emails, ImageEditor, Passwords, Phone, Policies } from "../widgets";

import axiosCall from "../../lib/axios";

const Account = ({ language, signout, checkPolicies, main_focus }) => {
  ///////////////// INITIALIZE /////////////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const userData = useSelector((state) => state.basic.value);
  const dispatch = useDispatch();
  console.log(auth);
  //avatar
  const canvas_ref = useRef();
  const [showAvatar, setShowAvatar] = useState(false);
  const [file, setFile] = useState(null);

  //basic details
  const [fullName, setFullName] = useState(userData.fullName || "");
  const [bio, setBio] = useState(userData.bio || "");
  const [removeName, setRemoveName] = useState(userData.fullName || "");
  const [removeBio, setRemoveBio] = useState(userData.bio || "");

  //error states
  const [errFullName, setErrFullName] = useState(null);
  const [errBio, setErrBio] = useState(null);
  const [errAvatar, setErrAvatar] = useState(null);

  function clearErrors() {
    setErrFullName(null);
    setErrBio(null);
    setErrAvatar(null);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFullName(userData.fullName || "");
    setBio(userData.bio || "");
    setRemoveName(userData.fullName || "");
    setRemoveBio(userData.bio || "");
  }, [userData]);

  ////////////// AVATAR //////////////
  const changeAvatar = async (e, change = true, reset = false) => {
    let click = true;
    if (e.key) click = false;
    clearErrors();

    if (!change) {
      setShowAvatar(false);
      setFile(null);
      if (!click) document.getElementById("toggle_image_editor").focus();
      return;
    }

    setFile(null);
    let canvas = canvas_ref.current;
    let data = { remove: userData.avatar };
    if (reset) {
      data.add = "reset";
    } else if (canvas && file) {
      var data_uri = canvas.toDataURL();
      data.add = data_uri;
    } else {
      setShowAvatar(false);
      if (!click) document.getElementById("toggle_image_editor").focus();
      return;
    }

    // try and submit data
    let result = await axiosCall("user/avatar", data);
    if (result.success) {
      let userState = { ...userData, avatar: result.data };
      localStorage.setItem("basic", JSON.stringify(userState));
      dispatch(setBasic(userState));
      setFile(null);
      setShowAvatar(false);
      if (!click) document.getElementById("toggle_image_editor").focus();
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeAvatar(e, change, reset);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  function toggleImageEditor() {
    if (showAvatar) setFile(null);
    setShowAvatar(!showAvatar);
  }

  ////////////// BASIC DETAILS //////////////
  const changeBasic = async (e, change = true) => {
    let val = "fullName";
    let txt = fullName.trim();
    let _txt = userData.fullName || "";
    if (e.target.getAttribute("name") === "bio_field") {
      val = "bio";
      txt = bio.trim();
      _txt = userData.bio || "";
    }
    let keyed = null;
    if (e.key) keyed = e.target.getAttribute("name");
    clearErrors();

    //check for changes
    if (!change || txt === _txt || txt === "") {
      //just reset and close editor
      setBlur(val, true, keyed);
      return;
    }

    //get submit data
    let data = {};
    data[val] = txt;

    // try and submit data
    let result = await axiosCall("user/edit", data);
    if (result.success) {
      if (result?.data._id) {
        //SUCCESS
        let userState = { ...userData };
        if (val === "fullName") userState.fullName = txt;
        if (val === "bio") userState.bio = txt;
        localStorage.setItem("basic", JSON.stringify(userState));
        dispatch(setBasic(userState));
        setBlur(e.target.getAttribute("name"), true, keyed);
      } else {
        //unknown error
        if (val === "fullName") {
          setErrFullName(language.labels.error.try_again);
        }
        if (val === "bio") {
          setErrBio(language.labels.error.try_again);
        }
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeBasic(e, change);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const removeBasic = async (field) => {
    let data = {};
    data[field] = true;

    // try and submit data
    let result = await axiosCall("user/unset", data);
    if (result.success) {
      if (result.data.n && result.data.n > 0) {
        //SUCCESS
        let userState = { ...userData };
        if (field === "fullName") userState.fullName = "";
        if (field === "bio") userState.bio = "";
        localStorage.setItem("basic", JSON.stringify(userState));
        dispatch(setBasic(userState));
      } else {
        //unknown error
        if (field === "fullName") {
          setErrFullName(language.labels.error.try_again);
        }
        if (field === "bio") {
          setErrBio(language.labels.error.try_again);
        }
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      removeBasic(field);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //clear focus
  const clearFocus = (excluding = "") => {
    setShowAvatar(false);
    setFile(null);
    if (excluding !== "name_field") setFullName(userData.fullName || "");
    if (excluding !== "bio_field") setBio(userData.bio || "");
  };
  const setBlur = (name, reblur = false, keyed = null) => {
    //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
    setTimeout(() => {
      if (reblur) {
        document.activeElement.blur();
      } else if (name !== document.activeElement.getAttribute("name")) {
        clearFocus();
      }
      if (keyed) {
        //set focus to next field
        if (keyed === "name_field") {
          document.getElementById("bio_field").focus();
        } else {
          document.getElementById("add_email_input").focus();
        }
      }
    }, 100);
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <main
      className="main-page"
      style={{ marginBottom: "2rem" }}
      ref={main_focus}
      tabIndex={0}
    >
      {/* Heading */}
      <div className="page-section">
        <h1 className="heading mobile-center">
          {language.labels.navigation.account}
        </h1>
      </div>

      {/*Account Details */}
      <div className="page-section" style={{ marginTop: "0.5em" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">S</div>
          <div className="subheading">{language.labels.account.heading}</div>
        </div>

        {/* username */}
        <div
          className="mobile-justify"
          style={{
            marginTop: "0.25em",
            display: "flex",
            gap: "0.5em",
            alignItems: "baseline",
          }}
        >
          <div style={{ fontWeight: 300 }}>
            {language.labels.authentication.username}
          </div>
          <div>{userData.username}</div>
        </div>

        {/* account type */}
        <div
          className="mobile-justify"
          style={{
            marginTop: "0.25em",
            display: "flex",
            gap: "0.5em",
            alignItems: "baseline",
          }}
        >
          <div style={{ fontWeight: 300 }}>{language.labels.account.type}</div>
          <div>{language.labels.account.types[auth.account_type]}</div>
        </div>
      </div>

      {/* Basic Details */}
      <div className="page-section" style={{ marginTop: "1em" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">B</div>
          <div className="subheading">{language.labels.account.about}</div>
        </div>
      </div>

      {/* Avatar */}
      <div className="page-section mobile-justify" style={{ display: "flex" }}>
        <div
          className="avatar large thumb"
          style={{
            backgroundImage: `URL(${
              config.server.storage.bucket + userData.avatar
            })`,
          }}
        >
          <button
            id="toggle_image_editor"
            className="button-edit left50"
            style={{ padding: "0 .5em", position: "absolute", bottom: 0 }}
            aria-label={language.labels.app.change}
            onClick={() => {
              toggleImageEditor();
            }}
          >
            <div aria-hidden="true" className="glyphs font-contrast">
              w
            </div>
            <div style={{ padding: "0 .2rem" }}></div>
            <div style={{ flexGrow: 0 }}>{language.labels.app.change}</div>
          </button>
        </div>
      </div>
      {errAvatar && (
        <div
          className="page-section mobile-justify"
          style={{ paddingTop: "0px", display: "flex" }}
        >
          <div className="errtext" role="alert">
            {errAvatar}sss
          </div>
        </div>
      )}
      {showAvatar && (
        <div
          className="page-section mobile-margin"
          style={{ paddingTop: "0px", maxWidth: "44rem" }}
        >
          <ImageEditor
            file={file}
            setFile={setFile}
            language={language}
            apply={changeAvatar}
            clear={true}
            radius="50%"
            ref={canvas_ref}
          />
        </div>
      )}

      {/* FullName and Bio */}
      <div
        className="page-section mobile-center"
        style={{ marginTop: "0.5em" }}
      >
        {/* FullName */}
        <label className="text-label" htmlFor="name_field">
          {language.labels.account.name_heading}
        </label>
        <div
          className="natural-edit"
          style={{ display: "flex", maxWidth: "22rem" }}
        >
          <input
            autoComplete="off"
            style={{ flex: 1 }}
            id="name_field"
            name="name_field"
            value={fullName}
            aria-placeholder={language.labels.account.name}
            placeholder={language.labels.account.name}
            onChange={(e) => setFullName(e.target.value)}
            onFocus={() => clearFocus("name_field")}
            onBlur={() => setBlur("name_field")}
            onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
            maxLength={config.string.title}
          />
          <div
            style={{
              flexBasis: "3.5rem",
              display: "flex",
              justifyContent: "space-around",
              margin: "-0.25rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="name_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={() => setBlur("name_field")}
              onClick={(e) => changeBasic(e)}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="name_field"
              style={{ fontSize: "0.7rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={() => setBlur("name_field")}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
        {removeName && (
          <button
            className="link"
            style={{ fontSize: ".8rem", padding: "0 .3rem" }}
            onClick={() => removeBasic("fullName")}
          >
            {language.labels.account.name_remove}
          </button>
        )}
        {errFullName && (
          <div className="errtext" role="alert">
            {errFullName}
          </div>
        )}

        {/* Bio */}
        <label
          className="text-label"
          style={{ display: "block", marginTop: "1em" }}
          htmlFor="bio_field"
          aria-hidden="true"
        >
          {language.labels.account.bio_heading}
        </label>
        <div
          className="natural-edit"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "44rem",
          }}
        >
          <TextareaAutosize
            minRows={4}
            style={{ width: "100%" }}
            id="bio_field"
            name="bio_field"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            onFocus={() => clearFocus("bio_field")}
            onBlur={() => setBlur("bio_field")}
            aria-placeholder={language.labels.account.bio}
            placeholder={language.labels.account.bio}
            maxLength={config.string.bio}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 0,
              margin: "0 -0.5rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="bio_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={() => setBlur("bio_field")}
              onClick={(e) => {
                changeBasic(e);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="bio_field"
              style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={() => setBlur("bio_field")}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
        {removeBio && (
          <button
            className="link"
            style={{ fontSize: ".8rem", padding: "0 .3rem" }}
            onClick={() => removeBasic("bio")}
          >
            {language.labels.account.bio_remove}
          </button>
        )}
        {errBio && (
          <div className="errtext" role="alert">
            {errBio}
          </div>
        )}
      </div>

      {/* Phone Number */}
      <div className="page-section" style={{ marginTop: "2em" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">R</div>
          <div className="subheading">
            {language.labels.authentication.phone_number}
          </div>
        </div>
        <Phone language={language} signout={signout} />
      </div>

      {/* Email */}
      <div className="page-section" style={{ marginTop: "2em" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">E</div>
          <div className="subheading">
            {language.labels.account.email_accounts}
          </div>
        </div>
        <Emails
          language={language}
          signout={signout}
          phone={userData.phone_number ? true : false}
        />
      </div>

      {/* Password */}
      <div className="page-section" style={{ marginTop: "2em" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">L</div>
          <div className="subheading">
            {language.labels.account.password_change}
          </div>
        </div>

        <Passwords language={language} signout={signout} />
      </div>

      {/* Support */}
      <div className="page-section" style={{ marginTop: "2em" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">I</div>
          <div className="subheading" style={{ overflow: "unset" }}>
            {language.labels.app.support}
          </div>
        </div>

        <Policies
          props={{ terms: true, privacy: true }}
          checkPolicies={checkPolicies}
          language={language}
        />
      </div>

      {/* Delete Account */}
      <div
        className="page-section"
        style={{ marginTop: "2em", paddingBottom: "4em" }}
      >
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">T</div>
          <div className="subheading" style={{ overflow: "unset" }}>
            {language.labels.account.delete.account}
          </div>
        </div>

        <div
          className="mobile-center"
          style={{
            padding: ".3rem",
            marginTop: "0.2em",
          }}
        >
          <button
            className="link"
            onClick={() => {
              navigate("/account_deletion");
            }}
          >
            {language.labels.account.delete.my}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Account;
