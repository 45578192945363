import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setCommunities } from "../../features/communities";
import { setContext, unsetContext } from "../../features/context";
import { login } from "../../features/authentication";

import config from "../../config";
import Modal from "../modal/Modal";
import {
  CommunityBasic,
  CommunityEdit,
  CommunityMembership,
  ImageEditor,
} from "../widgets";

import encryption from "../../lib/encryption";
import axiosCall from "../../lib/axios";
import validator from "../../lib/validation";

const Context = ({ language, signout, main_focus }) => {
  ///////////////// INITIALIZE /////////////////
  const auth = useSelector((state) => state.authentication.value);
  const community = useSelector((state) => state.context.value);
  const communities = useSelector((state) => state.communities.value);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editCommunity, setEditCommunity] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [confirmClosure, setConfirmClosure] = useState(false);
  const [closeButton, setCloseButton] = useState("");
  const [closeLabel, setCloseLabel] = useState("");
  const [closeWarning, setCloseWarning] = useState("");
  const [closeConfirm, setCloseConfirm] = useState("");
  const [closeNow, setCloseNow] = useState("");
  const [quitCommunity, setQuitCommunity] = useState(false);

  const [crest, setCrest] = useState(community.crest);
  const canvas_ref = useRef();
  const [showCrest, setShowCrest] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (auth.level > 3) {
      //owner - close community
      setCloseButton(language.labels.communities.close_community);
      setCloseLabel(language.labels.communities.close);
      setCloseWarning(language.labels.communities.close_warning);
      setCloseConfirm(language.labels.app.consequences_understood);
      setCloseNow(language.labels.communities.close_now);
      setQuitCommunity(true);
    } else {
      //not owner - leave community
      setCloseButton(language.labels.communities.leave_community);
      setCloseLabel(language.labels.communities.leave);
      setCloseWarning(language.labels.communities.leave_warning);
      setCloseConfirm(language.labels.app.yes_sure);
      setCloseNow(language.labels.communities.leave_now);
      setQuitCommunity(false);
    }
  }, []);

  ////////////// CLOSE & LEAVE COMMUNITY //////////////
  const quitCommunityNow = async (close) => {
    let api = "member/leave";
    if (close) api = "community/close";
    let result = await axiosCall(api, { cid: community.id });

    if (result.success && result.status === 200) {
      //update jwt
      localStorage.setItem("last_updated", Date.now());
      localStorage.setItem("tag", encryption.encrypt(result.data.jwt));

      //unset context
      dispatch(unsetContext());
      localStorage.removeItem("context");

      //reset communities
      const _communities = await spliceCommunities();
      dispatch(setCommunities(_communities));
      localStorage.setItem("communities", JSON.stringify(_communities));

      //update auth
      let _auth = { ...auth };
      if (_auth.community_id) delete _auth.community_id;
      if (_auth.role) delete _auth.role;
      if (_auth.level) delete _auth.level;
      dispatch(login(_auth));
      localStorage.setItem("auth", JSON.stringify(_auth));

      navigate("/hub");
    } else if (result.refresh) {
      //token has been refreshed, try again
      quitCommunityNow(close);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  async function spliceCommunities() {
    let _communities = [...communities];
    for (var i = 0; i < _communities.length; i++) {
      if (_communities[i].id === community.id) {
        _communities.splice(i, 1);
        return _communities;
      }
    }
  }

  ////////////// CREST //////////////
  async function resetCommunities(obj) {
    let _communities = [...communities];
    for (var i = 0; i < _communities.length; i++) {
      if (_communities[i].id === community.id) {
        _communities[i] = obj;
        return _communities;
      }
    }
  }

  const resetCrest = () => {
    setShowCrest(false);
    setFile(null);
  };

  function toggleImageEditor() {
    if (showCrest) setFile(null);
    setShowCrest(!showCrest);
  }

  const changeCrest = async (e, change = true) => {
    let click = true;
    if (e.key) click = false;
    if (!change) {
      resetCrest();
      if (!click) document.getElementById("toggle_image_editor").focus();
      return;
    }
    setFile(null);
    let canvas = canvas_ref.current;
    let data = { cid: community.id, current_crest: community.crest };
    if (canvas && file) {
      data.crest = canvas.toDataURL();
    } else {
      resetCrest();
      if (!click) document.getElementById("toggle_image_editor").focus();
      return;
    }
    // try and submit data
    let result = await axiosCall("community/crest", data);
    if (result.success) {
      setCrest(result.data);
      const _community = {
        ...community,
        crest: result.data,
      };

      // set context
      dispatch(setContext(_community));
      localStorage.setItem("context", JSON.stringify(_community));

      // reset communities
      const _communities = await resetCommunities(_community);
      dispatch(setCommunities(_communities));
      localStorage.setItem("communities", JSON.stringify(_communities));

      resetCrest();
      if (!click) document.getElementById("toggle_image_editor").focus();
    } else if (result.refresh) {
      // token has been refreshed, try again
      changeCrest(e);
    } else {
      // refresh token expired or unknown error
      signout();
    }
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <div
        className="page-section"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{ display: "flex", alignItems: "center", maxWidth: "44rem" }}
        >
          {/* Icon and Heading */}
          <div
            role="img"
            aria-label={language.labels.aria.logo.replace(
              /{org}/g,
              community.title
            )}
            className="menu-crest"
            style={{
              height: "3em",
              width: "3em",
              backgroundImage: `url("${config.server.storage.bucket + crest}")`,
            }}
          ></div>
          <div style={{ width: "0.5em" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              width: "calc(100% - 2.5em)",
            }}
          >
            <h1 className="heading" style={{ width: "100%", padding: "0" }}>
              {community.uname}
            </h1>
            <div className="handle2" style={{ width: "100%" }}>
              {community.title}
            </div>
          </div>
          <div style={{ width: "0.5em" }}></div>
        </div>

        {/* Role */}
        <div
          style={{ fontSize: "1.1em", marginTop: "0.5em" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.role.replace(
              /{role}/g,
              language.labels.roles[community.role]
            ),
          }}
        />
        {auth.level === 3 && (
          <div style={{ marginTop: "0.25em" }}>
            {language.labels.communities.editor.replace(
              /{role}/g,
              language.labels.roles[community.role]
            )}
          </div>
        )}
        {auth.level === 4 && (
          <div style={{ marginTop: "0.25em" }}>
            {language.labels.communities.owner}
          </div>
        )}
      </div>

      {/********** SETTINGS & CONFIGURATION ***********/}
      {!editMode &&
        auth.level > 2 &&
        (editCommunity ? (
          <CommunityEdit
            language={language}
            signout={signout}
            setEditCommunity={setEditCommunity}
            auth={auth}
          />
        ) : (
          <div className="page-section">
            {/* configuration */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0.5em",
              }}
            >
              <div className="mobile-justify" style={{ display: "flex" }}>
                <div className="heading-icon">s</div>
                <div className="subheading" style={{ overflow: "initial" }}>
                  {language.labels.app.config}
                </div>
              </div>

              {auth.level === 5 && (
                <div
                  aria-hidden="true"
                  className="glyphs font-contrast hover"
                  style={{ padding: "0.5em" }}
                  onClick={() => {
                    setEditCommunity(true);
                  }}
                >
                  w
                </div>
              )}
            </div>

            {community.draft ? (
              // privacy
              <div
                style={{
                  display: "flex",
                  gap: "0.75em",
                  margin: "0.5em 0.3em",
                }}
              >
                <span className="glyphs">!</span>
                <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                  {language.labels.communities.draft}
                </span>
              </div>
            ) : (
              <>
                {/* privacy */}
                <div
                  style={{
                    display: "flex",
                    gap: "0.75em",
                    margin: "0.5em 0.3em",
                  }}
                >
                  <span className="glyphs">
                    {community.private ? "L" : "K"}
                  </span>
                  <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                    {language.labels.communities.type[community.type]}
                  </span>
                </div>

                {/* verified */}
                <div
                  style={{
                    display: "flex",
                    gap: "0.75em",
                    margin: "0.5em 0.3em",
                  }}
                >
                  {community.verified ? (
                    <>
                      <span className="glyphs">*</span>
                      <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                        {language.labels.communities.settings.verified}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="glyphs">x</span>
                      <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                        {language.labels.communities.settings.unverified}
                      </span>
                    </>
                  )}
                </div>

                {/* contact */}
                {auth.level < 5 && (
                  <div style={{ marginTop: "1em" }}>
                    {language.labels.communities.config}
                  </div>
                )}

                {/* settings */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2.5em",
                  }}
                >
                  <div className="mobile-justify" style={{ display: "flex" }}>
                    <div className="heading-icon">|</div>
                    <div className="subheading" style={{ overflow: "initial" }}>
                      {language.labels.app.settings}
                    </div>

                    {auth.level > 2 && (
                      <div
                        aria-hidden="true"
                        className="glyphs font-contrast hover"
                        style={{ padding: "0.5em" }}
                        onClick={() => {
                          setEditCommunity(true);
                        }}
                      >
                        w
                      </div>
                    )}
                  </div>
                </div>

                {/* ex_video */}
                <div
                  style={{
                    display: "flex",
                    gap: "0.75em",
                    margin: "0.5em 0.3em",
                  }}
                >
                  {community.ex_video ? (
                    <>
                      <span className="glyphs">_</span>
                      <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                        {language.labels.communities.settings.ex_video}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="glyphs">v</span>
                      <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                        {language.labels.communities.settings.open_video}
                      </span>
                    </>
                  )}
                </div>

                {/* ex_knowledge */}
                <div
                  style={{
                    display: "flex",
                    gap: "0.75em",
                    margin: "0.5em 0.3em",
                  }}
                >
                  {community.ex_knowledge ? (
                    <>
                      <span className="glyphs">`</span>
                      <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                        {language.labels.communities.settings.ex_knowledge}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="glyphs">l</span>
                      <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                        {language.labels.communities.settings.open_knowledge}
                      </span>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        ))}

      {/********** MEMBERSHIP ***********/}
      {!editMode && !editCommunity && (
        <div
          className="page-section"
          style={{ marginTop: `${auth.level > 2 ? "2em" : "1em"}` }}
        >
          {(auth.level > 1 ||
            (auth.level > 0 && community.type === "institution")) && (
            <div className="mobile-justify" style={{ display: "flex" }}>
              <div className="heading-icon">o</div>
              <div className="subheading">
                {language.labels.communities.membership.replace(
                  /{org}/g,
                  community.title
                )}
              </div>
            </div>
          )}

          {/* manage membership */}
          {(auth.level > 2 ||
            (auth.level > 0 && community.type === "institution")) && (
            <CommunityMembership language={language} signout={signout} />
          )}

          {/* directory */}
          <div
            style={{
              display: "flex",
              gap: "0.5em",
              alignItems: "center",
              marginTop: `${
                auth.level > 1 ||
                (auth.level > 0 && community.type === "institution")
                  ? "1.5em"
                  : "0"
              }`,
            }}
          >
            <button
              className="button-secondary"
              onClick={() => navigate("/directory")}
            >
              {language.labels.communities.directory}
            </button>
          </div>
        </div>
      )}

      {/********** ABOUT ***********/}
      {!editCommunity && (
        <div>
          <div
            className="page-section"
            style={{
              marginTop: `${
                editMode ||
                (auth.level < 3 &&
                  (auth.level < 1 || community.type !== "institution"))
                  ? "1em"
                  : "2em"
              }`,
            }}
          >
            <div className="mobile-justify" style={{ display: "flex" }}>
              <div className="heading-icon">b</div>
              <div className="subheading">
                {language.labels.communities.about_community.replace(
                  /{org}/g,
                  community.title
                )}
              </div>
            </div>
          </div>

          {/* crest */}
          <div
            className="page-section"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {editMode && (
              <h2
                className="heading2 alert"
                style={{
                  fontSize: "1.1em",
                  fontWeight: "600",
                  margin: "0 0.25em 1em 0",
                }}
              >
                {language.labels.app.edit_mode}
              </h2>
            )}

            {editMode && auth.level > 2 && (
              <label className="text-label" style={{ marginBottom: "0.25em" }}>
                {language.labels.communities.profile_image}
              </label>
            )}
            <div
              className="thumb-block border"
              style={{
                backgroundImage: `url("${
                  config.server.storage.bucket + community.crest
                }")`,
              }}
            >
              {editMode && auth.level > 2 && (
                <button
                  id="toggle_image_editor"
                  className="button-edit left50"
                  style={{
                    padding: "0 .5em",
                    position: "absolute",
                    bottom: "-0.5em",
                  }}
                  title={language.labels.app.change}
                  aria-label={language.labels.app.change}
                  onClick={() => {
                    toggleImageEditor();
                  }}
                >
                  <div aria-hidden="true" className="glyphs font-contrast">
                    w
                  </div>
                  <div style={{ padding: "0 .2rem" }}></div>
                  <div style={{ flexGrow: 0 }}>
                    {language.labels.app.change}
                  </div>
                </button>
              )}
            </div>
          </div>
          {showCrest && (
            <div
              className="page-section"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "44rem",
              }}
            >
              <ImageEditor
                file={file}
                setFile={setFile}
                language={language}
                apply={changeCrest}
                ref={canvas_ref}
              />
            </div>
          )}

          {/* title, description and website */}
          {editMode && auth.level > 2 ? (
            <CommunityBasic
              language={language}
              resetCrest={resetCrest}
              signout={signout}
              ref={main_focus}
            />
          ) : (
            <div className="page-section" style={{ maxWidth: "44rem" }}>
              {auth.level < 3 && (
                <>
                  {/* verified */}
                  {community.verified && (
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      <span className="glyphs">*</span>
                      <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                        {language.labels.public.verified}
                      </span>
                    </div>
                  )}

                  {/* privacy */}
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5em",
                      marginTop: "0.5em",
                    }}
                  >
                    <span className="glyphs">
                      {community.private ? "L" : "K"}
                    </span>
                    <span style={{ opacity: 0.8, fontStyle: "italic" }}>
                      {community.private
                        ? language.labels.communities.settings.private
                        : language.labels.communities.settings.public}
                    </span>
                  </div>
                </>
              )}

              <div style={{ marginTop: `${auth.level < 3 ? "1em" : "0"}` }}>
                {community.description}
              </div>
              {validator.URLchecker(community.website).host && (
                <div style={{ marginTop: "1em" }}>
                  <a href={community.website} target={community.uname}>
                    {validator.URLchecker(community.website).host}
                  </a>
                </div>
              )}
            </div>
          )}

          {/* edit button */}
          {auth.level > 2 && (
            <div className="page-section" style={{ paddingTop: "0" }}>
              {editMode ? (
                <div
                  style={{ display: "flex", gap: "0.25em", margin: "3em 0" }}
                >
                  <button
                    className="button-secondary"
                    onClick={() => setEditMode(false)}
                  >
                    {language.labels.app.edit_mode_leave}
                  </button>
                </div>
              ) : (
                <button
                  className="button-edit"
                  style={{ padding: "0 1em", marginTop: "1.5em" }}
                  title={language.labels.communities.edit}
                  aria-label={language.labels.communities.edit}
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <div aria-hidden="true" className="glyphs font-contrast">
                    w
                  </div>
                  <div style={{ padding: "0 .2rem" }}></div>
                  <div style={{ flexGrow: 0 }}>
                    {language.labels.communities.edit}
                  </div>
                </button>
              )}
            </div>
          )}

          {/* Leave / Close Button */}
          {!editMode && (
            <div className="page-section" style={{ paddingBottom: "2em" }}>
              <button
                className="button"
                style={{ fontSize: "0.85em", marginTop: "2em" }}
                onClick={() => setShowModal(true)}
              >
                {closeButton}
              </button>
            </div>
          )}

          {/* Leave/Close Modal */}
          <Modal
            className="modal-dynamic"
            title={closeLabel.replace(/{org}/g, community.title)}
            show={showModal}
          >
            <div
              style={{ fontSize: "1.1em" }}
              dangerouslySetInnerHTML={{
                __html: closeWarning.replace(/{org}/g, community.title),
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: ".9rem",
                marginTop: "1rem",
              }}
            >
              <input
                id="consequence_understood"
                type={"checkbox"}
                onChange={(e) => setConfirmClosure(e.target.checked)}
                defaultChecked={confirmClosure}
              />
              <div style={{ minWidth: "0.5rem" }}></div>
              <label className="hover" htmlFor="consequence_understood">
                {closeConfirm}
              </label>
            </div>
            {confirmClosure && (
              <div style={{ display: "flex", marginTop: "0.5em" }}>
                <button
                  className="button"
                  onClick={() => quitCommunityNow(quitCommunity)}
                >
                  {closeNow}
                </button>
              </div>
            )}
            <div style={{ display: "flex", marginTop: "2em" }}>
              <button
                className="button-off"
                onClick={() => setShowModal(false)}
              >
                {language.labels.app.cancel}
              </button>
            </div>
          </Modal>
        </div>
      )}
    </main>
  );
};

export default Context;
