import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import config from "../../config";
import { KnowledgeViewer } from "../widgets";

const Thread = ({ language, main_focus, signout }) => {
  //////////// INITIALIZE ///////////
  const { state } = useLocation();
  const navigate = useNavigate();
  const community = useSelector((state) => state.context.value);

  const [currentThread, setCurrentThread] = useState({});
  const [removedAnswer, setRemovedAnswer] = useState(null);

  useEffect(() => {
    if (!currentThread._id) {
      setCurrentThread(state.thread);
    }
  }, []);

  ////////////// FUNCTIONS //////////////////

  const closeThread = () => {
    navigate("/knowledge");
  };

  const removeKnowledge = (answer) => {
    if (answer) {
      setRemovedAnswer(answer);
    }
  };

  const navBite = () => {
    navigate("/bite", {
      state: { bid: currentThread.block, thread: currentThread },
    });
  };

  //////////// RENDER GUI ///////////
  if (currentThread._id) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        {/* heading */}
        <div className="page-section">
          <h1 className="heading">
            {language.labels.knowledge.type[currentThread.knowledge_type]}
          </h1>
        </div>

        {/* block */}
        {!state.flagged && (
          <div className="page-section">
            <h2 className="heading2" style={{ marginBottom: "0.25em" }}>
              {language.labels.videos.bite}
            </h2>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={currentThread.bite_name}
                className="menu-crest"
                style={{
                  width: "3em",
                  height: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + currentThread.bite_image
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <div
                  role="link"
                  className="link clamped one"
                  style={{
                    fontWeight: 500,
                    fontSize: "1.2em",
                    margin: "0.25em 0",
                  }}
                  onClick={navBite}
                  onKeyUpCapture={(e) => e.key === "Enter" && navBite()}
                  tabIndex={0}
                >
                  {currentThread.bite_name}
                </div>
                <div className="handle2" style={{ width: "100%" }}>
                  {community.title}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* view thread */}
        <div className="page-section">
          <KnowledgeViewer
            community={community}
            style={{ maxWidth: "1600px", paddingBottom: "2em" }}
            language={language}
            currentThread={currentThread._id}
            onClose={closeThread}
            onChange={removeKnowledge}
            onDelete={closeThread}
            removedAnswer={removedAnswer}
            flagged={state.flagged}
            report={state.report}
            signout={signout}
          ></KnowledgeViewer>
        </div>
      </main>
    );
  } else {
    return <></>;
  }
};

export default Thread;
