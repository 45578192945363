import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import axiosCall from "../../lib/axios";
import validator from "../../lib/validation";
import config from "../../config";
import Modal from "../modal/Modal";
import PathwayEditor from "../widgets/PathwayEditor";

const Pathway = ({ language, main_focus, signout }) => {
  //////////// INITIALIZE ///////////
  const community = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);

  const { state } = useLocation();
  const navigate = useNavigate();
  const pid = state.pid;

  const [pathway, setPathway] = useState({});
  const [bites, setBites] = useState([]);
  const [pending, setPending] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [aiModal, setAiModal] = useState(false);
  const abortAxios = useRef();
  const [topicErr, setTopicErr] = useState("");
  const [removeModal, setRemoveModal] = useState(false);

  useEffect(() => {
    if (!pathway._id) getPathway();
  }, []);

  //////////// PATHWAY FUNCTIONS ///////////

  const getPathway = async () => {
    setSuggestions([]);
    let result = await axiosCall("pathway/info", { pid });
    if (result.success) {
      setPathway(result.data.pathway);
      setBites(result.data.bites);
      if (result.data.pathway?.pending) setPending(result.data.pathway.pending);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPathway();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const removePathway = () => {
    if (bites.length > 0) {
      setRemoveModal(true);
    } else {
      deletePathway();
    }
  };

  const deletePathway = async () => {
    let result = await axiosCall("pathway/remove", { pid });
    if (result.success) {
      //back to pathways
      navigate("/pathways");
    } else if (result.refresh) {
      //token has been refreshed, try again
      deletePathway();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const cancelRemove = () => {
    setRemoveModal(false);
  };

  //////////// NAVIGATION ///////////

  //navigate to block
  const showBite = (id) => {
    navigate("/bite", { state: { bid: id, pid } });
  };

  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;
    contents[pos].focus();
  };
  const navResults = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let container = document.getElementById("blockResults");
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //////////// SUGGEST BITES ///////////

  const suggestBites = async () => {
    setTopicErr("");

    //show modal
    setAiModal(true);

    //set abort
    const timestamp = Date.now();
    abortAxios.current = timestamp;

    //call api
    let topic = pathway.pathway;
    if (pathway.description) topic += " - " + pathway.description;
    let result = await axiosCall("pathway/suggest", { topic }, false);

    //only process if not aborted
    if (timestamp === abortAxios.current) {
      if (result.success) {
        setAiModal(false);

        if (result.data.error || result.status === 201 || !result.data.titles) {
          //unknown error with AI
          setTopicErr(language.labels.error.unknown_later);
        } else if (result.data.titles.length > 0) {
          //ok
          setSuggestions(result.data.titles);
        } else {
          //exceeded token limit or gibberish input
          setTopicErr(language.labels.bites.error.topic_vague);
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        suggestBites();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  const abort = () => {
    abortAxios.current = 0;
    setAiModal(false);
  };

  const modifySuggested = (e) => {
    let tmp = [...suggestions];
    tmp[e.getAttribute("index")] = e.value;
    setSuggestions(tmp);
  };

  const removeSuggested = (e) => {
    let tmp = [...suggestions];
    tmp.splice(e.getAttribute("index"), 1);
    setSuggestions(tmp);
  };

  const addSuggestion = () => {
    setTopicErr("");
    let tmp = [...suggestions];
    tmp.push("");
    setSuggestions(tmp);
  };

  const saveSuggestions = async () => {
    //check suggestions for null entries
    let _suggestions = new Array();
    for (var i = 0; i < suggestions.length; i++) {
      let str = suggestions[i].trim();
      if (str.length > 0) _suggestions.push(str.slice(0, config.string.title));
    }

    if (_suggestions.length > 0) {
      //call api
      document.getElementById("loader").style.display = "inline";
      let result = await axiosCall(
        "pathway/append",
        { pid, bites: _suggestions },
        false
      );
      if (result.success) {
        getPathway();
      } else if (result.refresh) {
        //token has been refreshed, try again
        saveSuggestions();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } else {
      setSuggestions([]);
    }
  };

  //////////// PENDING BITES ///////////

  const reappend = async (bites) => {
    //check bites for null entries
    let _bites = new Array();
    for (var i = 0; i < bites.length; i++) {
      let str = bites[i].trim();
      if (str.length > 0) _bites.push(str.slice(0, config.string.title));
    }

    let result = await axiosCall("pathway/append", { pid, bites });
    if (result.success) {
      setPending(_bites);
      setDirty(false);
    } else if (result.refresh) {
      //token has been refreshed, try again
      reappend(bites);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const modifyPending = (e) => {
    let tmp = [...pending];
    tmp[e.getAttribute("index")] = e.value;
    //check if string is dirty
    if (e.getAttribute("index") < pathway?.pending.length) {
      if (
        tmp[e.getAttribute("index")].trim() ===
        pathway.pending[e.getAttribute("index")].trim()
      ) {
        setDirty(false);
      } else {
        setDirty(true);
      }
    }
    setPending(tmp);
  };

  const changePending = (e) => {
    let tmp = [...pending];
    tmp[e.getAttribute("index")] = e.value;
    if (dirty) reappend(tmp);
  };

  const removePending = (e) => {
    let tmp = [...pending];
    tmp.splice(e.getAttribute("index"), 1);
    reappend(tmp);
  };

  const createPending = () => {
    navigate("/newbites", {
      state: { topic: pathway.pathway, topicId: pid, bitesArr: pending },
    });
  };

  //////////// RENDER GUI ///////////

  const mappedBites = bites.map((bite, index) => (
    <div
      role="figure"
      aria-label={bite.title}
      key={index}
      className="card-long hover"
      onClick={() => {
        showBite(bite.bid);
      }}
      onKeyDown={(e) => navResults(e)}
      tabIndex={0}
    >
      <div
        role="img"
        aria-label={bite.title}
        style={{
          backgroundImage: `url(${config.server.storage.bucket + bite.thumb})`,
          width: "90px",
          height: "90px",
        }}
        className="thumb-block auto-margin-narrow"
      ></div>
      <div className="block-info">
        <div
          role="link"
          className="block-title"
          onKeyDown={(e) => e.key === "Enter" && showBite(bite.bid)}
          tabIndex={0}
        >
          {bite.title}
        </div>

        {/* third party */}
        {community.id !== bite.community && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              marginTop: "0.5em",
            }}
          >
            <div
              className="avatar small"
              style={{
                flexShrink: "0",
                backgroundImage: `url(${
                  config.server.storage.bucket + bite.poster_image
                })`,
                borderRadius: "6px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 30px)",
              }}
            >
              <div className="name" style={{ fontSize: "1em" }}>
                {bite.poster_name}
              </div>
              <div className="font-contrast" style={{ fontSize: "0.7em" }}>
                {language.labels.bites.third_party.toLowerCase()}
              </div>
            </div>
          </div>
        )}

        <div
          style={{ fontSize: ".9em", marginTop: "0.5em" }}
          className="clamped two"
        >
          {bite.about}
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "0.5em",
            alignItems: "center",
            opacity: "0.8",
          }}
        >
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.aria.bookmarks}
            data-tooltip-id="followers-tooltip"
            data-tooltip-content={language.labels.aria.bookmarks}
          >
            q
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {validator.bigNumber(bite.count_bookmarks || 0)}
          </div>
          <div style={{ flexBasis: "2em" }}></div>
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.navigation.videos}
            data-tooltip-id="videos-tooltip"
            data-tooltip-content={language.labels.navigation.videos}
          >
            v
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {validator.bigNumber(bite.count_video)}
          </div>
          <div style={{ flexBasis: "2em" }}></div>
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.navigation.knowledge}
            data-tooltip-id="knowledge-tooltip"
            data-tooltip-content={language.labels.navigation.knowledge}
          >
            k
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {validator.bigNumber(bite.count_knowledge)}
          </div>
        </div>
      </div>
    </div>
  ));

  const suggested = suggestions.map((suggestion, index) => (
    <div style={{ display: "flex", gap: "0.5em" }} key={index}>
      <input
        style={{ flex: "1", fontSize: "0.9em", padding: "4px 8px" }}
        value={suggestion.slice(0, config.string.title)}
        maxLength={config.string.title}
        index={index}
        onChange={(e) => {
          modifySuggested(e.target);
        }}
      />
      <button
        style={{ fontSize: "0.75em" }}
        className="glyphs link-delete"
        index={index}
        aria-label={language.labels.app.remove}
        onClick={(e) => {
          removeSuggested(e.target);
        }}
      >
        x
      </button>
    </div>
  ));

  const appended = pending.map((appendix, index) => (
    <div style={{ display: "flex", gap: "0.5em" }} key={index}>
      <input
        style={{ flex: "1", fontSize: "0.9em", padding: "4px 8px" }}
        value={appendix.slice(0, config.string.title)}
        maxLength={config.string.title}
        index={index}
        onChange={(e) => {
          modifyPending(e.target);
        }}
        onBlur={(e) => {
          changePending(e.target);
        }}
      />
      <button
        style={{ fontSize: "0.75em" }}
        className="glyphs link-delete"
        index={index}
        aria-label={language.labels.app.remove}
        onClick={(e) => {
          removePending(e.target);
        }}
      >
        x
      </button>
    </div>
  ));

  if (pathway.community && community.id == pathway.community) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        {/* heading */}
        <div className="page-section">
          <h1 className="heading">{language.labels.pathways.pathway}</h1>
        </div>

        {/* pathway */}
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            {pathway?.thumb ? (
              <div
                role="img"
                aria-label={pathway.pathway}
                style={{
                  backgroundImage: `url(${
                    config.server.storage.bucket + pathway.thumb
                  })`,
                  width: "3em",
                  height: "3em",
                }}
                className="menu-crest"
              ></div>
            ) : (
              <div
                role="img"
                aria-label={pathway.pathway}
                style={{
                  width: "1.875em",
                  height: "1.875em",
                  fontSize: "1.6em",
                  lineHeight: "1.875em",
                }}
                className="thumb-glyph"
              >
                p
              </div>
            )}
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h2
                className="heading"
                style={{ width: "100%", padding: "0", fontSize: "1.2em" }}
              >
                {pathway.pathway}
              </h2>
              <div className="handle2" style={{ width: "100%" }}>
                {community.title}
              </div>
            </div>
          </div>
        </div>

        {/* edit title */}
        {auth.level > 1 && (
          <div className="page-section" style={{ marginTop: "-1em" }}>
            <PathwayEditor
              language={language}
              pathway={pathway}
              setPathway={setPathway}
              main_focus={main_focus}
              signout={signout}
            />
          </div>
        )}

        {auth.level === 0 && pathway.description && (
          <div className="page-section quote-text">{pathway.description}</div>
        )}

        <div
          className="page-section"
          style={{ marginTop: "1em", paddingBottom: "2em" }}
        >
          {/* list the bites */}
          {bites.length > 0 ? (
            <>
              <h2 className="heading2">
                {language.labels.pathways.bites_n.replace(/{n}/g, bites.length)}
              </h2>
              <div id="blockResults">{mappedBites}</div>
            </>
          ) : (
            <div className="font-alt" style={{ fontWeight: 500 }}>
              {language.labels.pathways.no_bites}
            </div>
          )}

          {auth.level > 1 && (
            <>
              <div style={{ margin: "2em 0" }}>
                {bites.length > 0
                  ? language.labels.pathways.add_remove_bites
                  : language.labels.pathways.add_existing_bites}
              </div>

              {/* suggest bites */}
              {pending.length === 0 && suggestions.length === 0 && (
                <div style={{ display: "flex", gap: "1em" }}>
                  <button
                    className="button"
                    style={{ marginTop: "1em", display: "block" }}
                    onClick={() => {
                      addSuggestion();
                    }}
                  >
                    {language.labels.pathways.add_pending}
                  </button>

                  <button
                    className="button-secondary"
                    style={{ marginTop: "1em" }}
                    onClick={() => {
                      suggestBites();
                    }}
                  >
                    {language.labels.ai.use}
                  </button>
                </div>
              )}

              {/* suggestions */}
              {suggestions.length > 0 && (
                <>
                  <div
                    className="card-free"
                    style={{ maxWidth: "44rem", marginTop: "2em" }}
                  >
                    <h2 className="heading2" style={{ margin: "0.5em 0" }}>
                      {language.labels.pathways.suggest_bites_manually}
                    </h2>
                    <div>{suggested}</div>
                    <button
                      style={{
                        fontSize: "0.9em",
                        alignSelf: "flex-start",
                        marginTop: "0.5em",
                      }}
                      className="link"
                      onClick={() => {
                        addSuggestion();
                      }}
                    >
                      {language.labels.ai.add_another}
                    </button>

                    <div style={{ display: "flex", margin: "2em 0 0.5em" }}>
                      <button
                        className="button"
                        style={{ fontSize: ".8em" }}
                        onClick={() => {
                          saveSuggestions();
                        }}
                      >
                        {language.labels.app.add}
                      </button>
                      <div style={{ flexBasis: "0.25em" }} />
                      <button
                        className="button-cancel"
                        style={{ fontSize: ".8em" }}
                        onClick={() => {
                          setSuggestions([]);
                        }}
                      >
                        {language.labels.app.cancel}
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* pending bites */}
              {pending.length > 0 && (
                <>
                  <div
                    className="card-free"
                    style={{ maxWidth: "44rem", marginTop: "1em" }}
                  >
                    <h2 className="heading2" style={{ margin: "0.5em 0" }}>
                      {language.labels.pathways.pending}
                    </h2>
                    <div>{appended}</div>

                    <div style={{ margin: "2em 0 0.5em" }}>
                      <button
                        className="button"
                        style={{ fontSize: ".8em" }}
                        onClick={() => {
                          createPending();
                        }}
                      >
                        {language.labels.pathways.create_pending}
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* Remove Pathway */}
              <div style={{ margin: "3em 0" }}>
                <button
                  className="button-warning"
                  onClick={() => {
                    removePathway();
                  }}
                >
                  {language.labels.pathways.remove}
                </button>
              </div>
            </>
          )}

          {topicErr && (
            <div className="errtext" role="alert" style={{ margin: "2em 0" }}>
              {topicErr}
            </div>
          )}
        </div>

        {/* AI Modal */}
        <Modal className="modal-dynamic" show={aiModal}>
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">{language.labels.loader.wait}</h2>
            <div style={{ fontSize: "1.1em", padding: "0.5rem" }}>
              {language.labels.ai.moment}
            </div>
            <div
              className="dotdotdot"
              style={{ fontSize: "1.1em", padding: "0.5rem" }}
            >
              {language.labels.ai.generating}
            </div>

            <div style={{ display: "flex", margin: "1em 0.5em" }}>
              <button className="button-off" onClick={() => abort()}>
                {language.labels.ai.cancel}
              </button>
            </div>
          </div>
        </Modal>

        {/* Remove Modal */}
        <Modal
          className="modal-dynamic"
          show={removeModal}
          title={language.labels.pathways.remove}
          html={`<p>${language.labels.pathways.remove_warning1}</p><p>${language.labels.pathways.remove_warning2}</p>`}
          onClose={cancelRemove}
          onOk={deletePathway}
          language={language}
        ></Modal>

        {/* tooltips */}
        <Tooltip id="followers-tooltip" />
        <Tooltip id="videos-tooltip" />
        <Tooltip id="knowledge-tooltip" />
      </main>
    );
  } else {
    return <></>;
  }
};
export default Pathway;
