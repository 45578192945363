import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import config from "../../../config";
import TabList from "../../widgets/live/TabList";

const LiveStreams = ({ language, main_focus, signout }) => {
  //////////// INITIALIZATION ///////////
  const context = useSelector((state) => state.context.value);
  const navigate = useNavigate();

  //////////// KEY NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;
    contents[pos].focus();
  };
  const navResults = (e, container) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //////////// ACTION ///////////
  const goToStream = (streamId) => {
    navigate("/livestream", { state: { streamId } });
  };

  //////////// RENDER GUI ///////////
  if (context.free_streams) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                context.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.server.storage.bucket + context.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.navigation.live_streams}
              </h1>
              <div className="handle2" style={{ width: "100%" }}>
                {context.title}
              </div>
            </div>
          </div>
        </div>

        <div className="page-section" style={{ maxWidth: "66rem" }}>
          <div style={{ marginTop: "0.5em" }} className="quote-text">
            Live learning streams are currently in <b>beta</b> and only
            available in the Community Hub to community experts and instructors.
            It will be become available to all community members in release 2.0
            of the Yongo App.
          </div>

          {/* new stream */}
          {context.role !== "role_member" && (
            <button
              style={{
                alignSelf: "flex-start",
                marginTop: "0.5em",
                fontWeight: "500",
              }}
              className="button"
              onClick={() => {
                navigate("/pathwaystream");
              }}
            >
              {language.labels.live.start_new}
            </button>
          )}
        </div>

        <div className="page-section">
          <TabList
            language={language}
            signout={signout}
            params={{ context, goToStream, navResults }}
          />
        </div>
      </main>
    );
  } else {
    return <></>;
  }
};

export default LiveStreams;
