import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setCommunities } from "../../features/communities";
import { setContext, unsetContext } from "../../features/context";
import { login } from "../../features/authentication";

import axiosCall from "../../lib/axios";
import encryption from "../../lib/encryption";
import navGrid from "../../lib/thumbGrid";
import { sortArrObj } from "../../lib/common";
import config from "../../config";
import { Search, StaffEditor } from "../widgets";

function sortManagers(managers, lang) {
  let flattened = new Array();
  var i = 0,
    len = managers.length;
  while (i < len) {
    flattened.push({
      role: managers[i].role,
      uid: managers[i].manager_meta.uid,
      avatar: managers[i].manager_meta.avatar,
      username: managers[i].manager_meta.username,
      email: managers[i].email_alias,
      enterprize: managers[i].enterprize || false,
    });
    i++;
  }
  flattened.sort(sortArrObj("username", lang));
  return flattened;
}

const Managers = ({ language, signout, main_focus }) => {
  //////////// INITIALIZATION ///////////
  const context = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);
  const communities = useSelector((state) => state.communities.value);
  const userData = useSelector((state) => state.basic.value);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const search_ref = useRef();

  const [communityManagers, setCommunityManagers] = useState([]);
  const [allManagers, setAllManagers] = useState([]);
  const [grantedAccounts, setGrantedAccounts] = useState(0);

  //filters
  const [searchString, setSearchString] = useState("");
  const [role, setRole] = useState("all");
  const [name, setName] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  //editor
  const [edit, setEdit] = useState(-1);

  useEffect(() => {
    getManagers();
  }, []);

  const getManagers = async () => {
    let result = await axiosCall("staff/get");
    if (result.success) {
      let granted = result.data.filter((role) => role.enterprize).length;
      setGrantedAccounts(granted);
      let managers = sortManagers(result.data, language.lang);
      setCommunityManagers(managers);
      setAllManagers(managers);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getManagers();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const resetCap = async (obj) => {
    console.log(obj);
    //reset cap
    if (obj.enterprize_cap !== context.enterprize_cap) {
      let _context = { ...context, enterprize_cap: obj.enterprize_cap };
      dispatch(setContext(_context));
      localStorage.setItem("context", JSON.stringify(_context));
      const _communities = await spliceCommunities();
      _communities.push(_context);
      _communities.sort(sortArrObj("uname", language.lang));
      dispatch(setCommunities(_communities));
      localStorage.setItem("communities", JSON.stringify(_communities));
    }

    if (obj.user) {
      //refresh managers
      setGrantedAccounts(obj.granted);
      getManagers();
    }
  };

  //////////// FILTER SEARCH ///////////
  const searchManagers = (e) => {
    setSearchString(e);
    if (search_ref.current?.value) {
      filterManagers(search_ref.current.value, "SEARCH");
    } else {
      filterManagers("", "SEARCH");
    }
  };

  const toggleFilter = () => {
    if (showFilter) filterManagers("all", "ROLE");
    setShowFilter(!showFilter);
  };

  const filterButton = (e) => {
    if (role === e.value) {
      setRole("all");
      filterManagers("all", "ROLE");
    } else {
      setRole(e.value);
      filterManagers(e.value, "ROLE");
    }
  };

  const filterManagers = async (val, flag) => {
    let filter = { name: name, role: role };
    if (flag === "SEARCH") {
      setName(val);
      filter.name = val.trim();
    } else if (flag === "ROLE") {
      setRole(val);
      filter.role = val.trim();
    }
    let _managers = [...allManagers];
    let staff = _managers.filter((managers) => {
      return (
        (managers.username.toLowerCase().includes(filter.name.toLowerCase()) ||
          managers.email?.toLowerCase().includes(filter.name.toLowerCase())) &&
        (filter.role !== "all" ? managers.role === filter.role : true)
      );
    });

    setCommunityManagers(staff);
  };

  //////////// EDIT ///////////
  const handleEdit = (idx) => {
    setEdit(edit === idx ? -1 : idx);
  };

  const changeRole = async (old_role, new_role, uid) => {
    if (old_role !== new_role) {
      const data = { uid: uid, role: new_role, cid: context.id };
      let result = await axiosCall("staff/changerole", data);
      if (result.success) {
        if (result.data?.jwt) {
          //changed own role
          localStorage.setItem("last_updated", Date.now());
          localStorage.setItem("tag", encryption.encrypt(result.data.jwt));

          //reset communities
          const _communities = await resetCommunities(result.data.role);
          dispatch(setCommunities(_communities));
          localStorage.setItem("communities", JSON.stringify(_communities));

          //update auth
          let _auth = {
            ...auth,
            role: result.data.role,
            level: config.levels.indexOf(result.data.role),
          };
          dispatch(login(_auth));
          localStorage.setItem("auth", JSON.stringify(_auth));

          //update context
          let _context = { ...context, role: result.data.role };
          dispatch(setContext(_context));
          localStorage.setItem("context", JSON.stringify(_context));

          //if lower than director back to community page
          if (config.levels.indexOf(result.data.role) < 3)
            navigate("/mycommunity");
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        changeRole(old_role, new_role, uid);
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  const removeRole = async (user) => {
    let result = await axiosCall("staff/deleterole", { user });
    if (result.success) {
      if (result.data?.jwt) {
        //removed own role
        localStorage.setItem("last_updated", Date.now());
        localStorage.setItem("tag", encryption.encrypt(result.data.jwt));
        //reset communities
        const _communities = await spliceCommunities();
        dispatch(setCommunities(_communities));
        localStorage.setItem("communities", JSON.stringify(_communities));
        //unset context
        dispatch(unsetContext());
        localStorage.removeItem("context");
        //update auth
        let _auth = { ...auth };
        if (_auth.community_id) delete _auth.community_id;
        if (_auth.role) delete _auth.role;
        if (_auth.level) delete _auth.level;
        if (result.data?.account) _auth.account_type = result.data.account;
        dispatch(login(_auth));
        localStorage.setItem("auth", JSON.stringify(_auth));
        navigate("/hub");
      } else {
        //removed another's role
        setEdit(-1);
        getManagers();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      removeRole(user);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  async function spliceCommunities() {
    let _communities = [...communities];
    for (var i = 0; i < _communities.length; i++) {
      if (_communities[i].id === context.id) {
        _communities.splice(i, 1);
        return _communities;
      }
    }
  }

  async function resetCommunities(role) {
    let _communities = [...communities];
    for (var i = 0; i < _communities.length; i++) {
      if (_communities[i].id === context.id) {
        let obj = _communities[i];
        _communities[i] = { ...obj, role };
        return _communities;
      }
    }
  }

  ////////////// KEYBOARD INTERACTION //////////////////
  const keyAction = (e, i) => {
    //navigated grid
    if (e.target.tagName.toUpperCase() === "DIV") {
      //detect ctrl+key
      let key;
      if (e.ctrlKey || e.metaKey) {
        key = "CTRL+" + e.key;
      } else {
        key = e.key;
      }

      if (e.key === "Enter" || e.key === " ") {
        handleEdit(i);
      } else {
        const _key = config.thumb_grid_keys.indexOf(key);
        if (_key >= 0) {
          let container = document.getElementById("thumb_wall");
          let contents = container.querySelectorAll("div[tabindex]");
          navGrid(container, contents, key);
        }
      }
    }
  };

  ////////////// RENDER GUI //////////////////
  let community_managers = communityManagers.map((data, i) => (
    <div
      className="thumb-group hover"
      key={i}
      role="figure"
      aria-label={data.username}
      onKeyDown={(e) => keyAction(e, i)}
      onClick={(e) => {
        handleEdit(i);
      }}
      tabIndex="0"
    >
      <div className="thumb-group-content">
        <img
          className="profile-image"
          src={config.server.storage.bucket + data.avatar}
          alt={data.username}
        />

        <div
          className="handle"
          style={{ width: "100%", padding: "0 0.5em", minHeight: "1em" }}
        >
          {data.email}
        </div>

        <div
          className="name"
          style={{
            width: "100%",
            padding: "0.5em",
          }}
        >
          {data.uid === userData.uid ? (
            <>{data.username + " (" + language.labels.app.me + ")"}</>
          ) : (
            <>{data.username}</>
          )}
        </div>

        <div className="handle2" style={{ fontSize: "1em" }}>
          {context.enterprize_cap > 0 && data.enterprize
            ? language.labels.roles_starred[data.role]
            : language.labels.roles[data.role]}
        </div>
      </div>
    </div>
  ));

  if (auth.level > 2) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                context.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.server.storage.bucket + context.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.navigation.community_managers}
              </h1>
              <div className="handle2" style={{ width: "100%" }}>
                {context.title}
              </div>
            </div>
          </div>
        </div>
        {context.enterprize_cap > 0 && (
          <>
            <div className="page-section">
              <div>
                {language.labels.communities.enterprise_slots_total.replace(
                  /{n}/g,
                  context.enterprize_cap
                )}
              </div>
            </div>
            <div className="page-section">
              <div>
                {language.labels.communities.enterprise_slots_granted.replace(
                  /{n}/g,
                  grantedAccounts
                )}
              </div>
            </div>
          </>
        )}
        {edit < 0 ? (
          <>
            <div
              className="page-section"
              style={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "44em",
                gap: "0.25em",
              }}
            >
              <div>
                <label className="text-label">
                  {language.labels.communities.filter_managers}
                </label>
                <Search
                  search={searchString}
                  search_ref={search_ref}
                  filter={toggleFilter}
                  onChange={searchManagers}
                  placeholder={language.labels.communities.filter_placeholder}
                />
                {showFilter && (
                  <>
                    <label
                      className="text-label"
                      style={{
                        display: "block",
                        fontSize: "0.8em",
                        marginTop: "0.5em",
                      }}
                    >
                      {language.labels.app.filter}
                    </label>
                    <div
                      className="filter"
                      style={{
                        display: "flex",
                        gap: "0.5em",
                        margin: "0.25em 0 1em",
                      }}
                    >
                      <button
                        className={role === "role_instructor" ? "on" : "off"}
                        value="role_instructor"
                        onClick={(e) => filterButton(e.target)}
                      >
                        {language.labels.roles.role_instructor}
                      </button>
                      <button
                        className={role === "role_content" ? "on" : "off"}
                        value="role_content"
                        onClick={(e) => filterButton(e.target)}
                      >
                        {language.labels.roles.role_content}
                      </button>
                      <button
                        className={role === "role_director" ? "on" : "off"}
                        value="role_director"
                        onClick={(e) => filterButton(e.target)}
                      >
                        {language.labels.roles.role_director}
                      </button>
                      <button
                        className={role === "role_owner" ? "on" : "off"}
                        value="role_owner"
                        onClick={(e) => filterButton(e.target)}
                      >
                        {language.labels.roles.role_owner}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="page-section">
              {role === "all" && name.trim() === "" ? (
                <div>
                  {language.labels.communities.staff_total.replace(
                    /{n}/g,
                    communityManagers.length
                  )}
                </div>
              ) : (
                <div>
                  {language.labels.communities.staff_filtered.replace(
                    /{n}/g,
                    communityManagers.length
                  )}
                </div>
              )}
            </div>

            <div className="page-section">
              <section
                id="thumb_wall"
                className="thumb-group-container"
                aria-label={language.labels.navigation.community_managers}
              >
                {communityManagers.length > 0 ? (
                  community_managers
                ) : (
                  <i>{language.labels.communities.staff_filtered_none}</i>
                )}
              </section>
            </div>
          </>
        ) : (
          <div className="page-section" style={{ marginTop: "0.5em" }}>
            <StaffEditor
              member={communityManagers[edit]}
              language={language}
              signout={signout}
              params={{
                uid: userData.uid,
                setEdit,
                changeRole,
                level: auth.level,
                removeRole,
                getManagers,
                enterprize_cap: context.enterprize_cap,
                resetCap,
              }}
            />
          </div>
        )}
      </main>
    );
  } else {
    return <></>;
  }
};

export default Managers;
