import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import config from "../../config";
import axiosCall from "../../lib/axios";

const NewVideo = ({ language, signout, main_focus }) => {
  //initialize
  const navigate = useNavigate();
  const community = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);

  const [about, setAbout] = useState("");
  const [aboutErr, setAboutErr] = useState("");

  const findBlocks = async () => {
    setAboutErr("");

    if (about.trim() === "") {
      setAboutErr(language.labels.videos.error.about);
      document.getElementById("about_field").focus();
    } else {
      // find top matches
      let result = await axiosCall("block/compare", {
        text: about.trim(),
        quick: true,
      });
      if (result.success) {
        if (result.data.similar.length > 0) {
          navigate("/newbite", {
            state: {
              preamble: about,
              referredBy: "video",
              similar: result.data.similar,
            },
          });
        } else {
          navigate("/newbite", {
            state: { preamble: about, referredBy: "video" },
          });
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        findBlocks();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  if (auth.level < 2) {
    return <></>;
  } else {
    return (
      <main className="main-page" ref={main_focus} tabIndex="0">
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                community.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.server.storage.bucket + community.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.videos.add}
              </h1>
              <div className="handle2">{community.title}</div>
            </div>
          </div>
        </div>

        <div className="page-section" style={{ maxWidth: "44rem" }}>
          <h2
            className="heading2"
            style={{ fontWeight: "500", fontSize: "1.1em" }}
          >
            {language.labels.videos.about}
          </h2>
          <div style={{ marginTop: "0.5em" }}>
            {language.labels.videos.about_summary}
          </div>

          <TextareaAutosize
            id="about_field"
            value={about}
            onChange={(e) => {
              setAbout(e.target.value);
            }}
            placeholder={language.labels.videos.about_enter}
            aria-placeholder={language.labels.videos.about_enter}
            minRows="3"
            style={{ width: "100%" }}
            maxLength={config.string.paragraph}
          />

          {aboutErr && (
            <div className="errtext" role="alert">
              {aboutErr}
            </div>
          )}

          <div style={{ display: "flex", margin: "1.5em 0" }}>
            <button className="button" onClick={() => findBlocks()}>
              {language.labels.app.action_next}
            </button>
          </div>
        </div>
      </main>
    );
  }
};

export default NewVideo;
