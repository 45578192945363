import React, { useState, useEffect, useRef } from "react";

import config from "../../../config";

import axiosCall from "../../../lib/axios";
import { naturalDate } from "../../../lib/date";

const TabList = ({ language, signout, params }) => {
  //////////// INITIALIZATION ///////////
  const liveRef = useRef();
  const recentRef = useRef();

  const [current, setLive] = useState([]);
  const [retained, setRetained] = useState([]);
  const [tab, setTab] = useState(null);

  useEffect(() => {
    setTab("live");
  }, []);

  useEffect(() => {
    switch (tab) {
      case "recent":
        getRetained();
        break;
      case "live":
        getCurrent();
        break;
      default:
        return;
    }
  }, [tab]);

  //////////// DATA ///////////
  const getCurrent = async () => {
    let filter = "latest_learning";
    if (params.context.role === "role_member") filter = "live_learning";
    let result = await axiosCall("live/community/current", {
      community: params.context.id,
      filter,
    });
    if (result.success) {
      setLive(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getCurrent();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const getRetained = async () => {
    let result = await axiosCall("live/community/retained", {
      community: params.context.id,
      filter: "learning",
    });
    console.log(result);

    if (result.success) {
      setRetained(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getRetained();
    } else {
      //refresh token expired or unknown error
      // signout();
    }
  };

  //////////// TAB INTERACTIONS ///////////
  function switchTab(e) {
    const tp = ["live", "recent"];
    var n = tp.indexOf(e.target.id);
    if (n > -1) {
      if (e.key === "ArrowRight") n++;
      if (e.key === "ArrowLeft") n--;
      if (n > -1 && n < tp.length) {
        selectTab(tp[n]);
        document.getElementById(tp[n]).focus();
      }
    }
  }

  function selectTab(tab) {
    setTab(tab);
  }

  //////////// RENDER GUI ///////////
  const mapped_live = current.map((stream, index) => {
    return (
      <div
        role="figure"
        className="card-long hover"
        key={index}
        onClick={() => params.goToStream(stream._id)}
        onKeyDown={(e) => params.navResults(e, liveRef.current)}
        tabIndex={0}
      >
        {/* publisher */}
        <div>
          <div
            style={{
              backgroundImage: `url(${config.server.storage.bucket}${stream.pub_avatar})`,
              flexShrink: "0",
              borderRadius: "50%",
            }}
            className="thumb-block auto-margin-narrow"
          ></div>
          <div
            style={{
              fontWeight: "500",
              fontSize: "0.9em",
              textAlign: "center",
              marginTop: "2px",
            }}
          >
            {stream.pub_name}
          </div>
        </div>

        {/* details */}
        <div className="block-info">
          <div
            className="clamped one"
            style={{ fontWeight: "500", fontSize: "1.1em" }}
          >
            {stream.streamName}
          </div>

          <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
            {language.labels.live.started.replace(
              /{date}/g,
              naturalDate(stream.started, language.locale, language.labels.date)
            )}
          </div>

          <div
            className="contrast clamped one"
            style={{ fontStyle: "italic", marginTop: "0.5em" }}
          >
            {stream.pathwayTitle}
          </div>

          {/* status */}
          <div
            className={
              stream.stopping
                ? "amber"
                : stream.live
                ? "alert"
                : stream.ended
                ? "middle"
                : "amber"
            }
            style={{ fontWeight: 500, margin: "0.5em 0" }}
          >
            {stream.stopping
              ? language.labels.live.streaming.ending
              : stream.live
              ? language.labels.live.streaming.now
              : stream.ended
              ? language.labels.live.ended_time.replace(
                  /{time}/g,
                  naturalDate(
                    stream.ended,
                    language.locale,
                    language.labels.date
                  )
                )
              : language.labels.live.streaming.pending}
          </div>
        </div>
      </div>
    );
  });

  const mapped_recent = retained.map((stream, index) => {
    return (
      <div
        role="figure"
        className="card-long hover"
        key={index}
        onClick={() => params.goToStream(stream._id)}
        onKeyDown={(e) => params.navResults(e, recentRef.current)}
        tabIndex={0}
      >
        {/* publisher */}
        <div>
          <div
            style={{
              backgroundImage: `url(${config.server.storage.bucket}${stream.pub_avatar})`,
              flexShrink: "0",
              borderRadius: "50%",
            }}
            className="thumb-block auto-margin-narrow"
          ></div>
          <div
            style={{
              fontWeight: "500",
              fontSize: "0.9em",
              textAlign: "center",
              marginTop: "2px",
            }}
          >
            {stream.pub_name}
          </div>
        </div>

        {/* details */}
        <div className="block-info">
          <div
            className="clamped one"
            style={{ fontWeight: "500", fontSize: "1.1em" }}
          >
            {stream.streamName}
          </div>

          <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
            {language.labels.live.started.replace(
              /{date}/g,
              naturalDate(stream.started, language.locale, language.labels.date)
            )}
          </div>

          <div
            className="contrast clamped one"
            style={{ fontStyle: "italic", marginTop: "0.5em" }}
          >
            {stream.pathwayTitle}
          </div>

          {/* status */}
          <div
            className="middle"
            style={{ fontWeight: 500, margin: "0.5em 0" }}
          >
            {language.labels.live.ended_time.replace(
              /{time}/g,
              naturalDate(stream.ended, language.locale, language.labels.date)
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      {/* tab menu */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1.5em",
          margin: "0.5em 0 1em 0",
        }}
        role="tablist"
      >
        <div
          role="tab"
          id="live"
          className={`subtab ${tab !== "live" && "off"}`}
          onClick={() => selectTab("live")}
          aria-selected={tab === "live" ? "true" : "false"}
          tabIndex={tab === "live" ? "0" : "-1"}
          onKeyUpCapture={(e) => switchTab(e)}
        >
          <span>{language.labels.app.live}</span>
          <div></div>
        </div>

        <div
          role="tab"
          id="recent"
          className={`subtab ${tab !== "recent" && "off"}`}
          onClick={() => selectTab("recent")}
          aria-selected={tab === "recent" ? "true" : "false"}
          tabIndex={tab === "recent" ? "0" : "-1"}
          onKeyUpCapture={(e) => switchTab(e)}
        >
          <span>{language.labels.app.recent}</span>
          <div></div>
        </div>
      </div>

      {/* live tab */}
      {tab === "live" && (
        <>
          {/* refresh */}
          <div style={{ margin: "1em 0 0.5em 0", display: "flex", gap: "1em" }}>
            <div>
              {current.length < 1
                ? language.labels.live.live_learning.none
                : `${
                    current.length > 1
                      ? language.labels.live.live_learning.multiple.replace(
                          /{n}/g,
                          current.length
                        )
                      : language.labels.live.live_learning.one
                  }`}
            </div>

            <button
              aria-label={language.labels.aria.refresh}
              className="glyphs success"
              onClick={() => getCurrent(true)}
            >
              r
            </button>
          </div>

          <div
            ref={liveRef}
            style={{ maxWidth: "66rem", paddingBottom: "1em" }}
          >
            {mapped_live}
          </div>
        </>
      )}

      {/* recent tab */}
      {tab === "recent" && (
        <>
          {/* refresh */}
          <div style={{ margin: "1em 0 0.5em 0", display: "flex", gap: "1em" }}>
            <div>
              {retained.length < 1
                ? language.labels.live.recent.none
                : `${
                    current.length > 1
                      ? language.labels.live.recent.multiple.replace(
                          /{n}/g,
                          current.length
                        )
                      : language.labels.live.recent.one
                  }`}
            </div>

            <button
              aria-label={language.labels.aria.refresh}
              className="glyphs success"
              onClick={() => getCurrent(true)}
            >
              r
            </button>
          </div>

          <div
            ref={recentRef}
            style={{ maxWidth: "66rem", paddingBottom: "1em" }}
          >
            {mapped_recent}
          </div>
        </>
      )}
    </>
  );
};

export default TabList;
