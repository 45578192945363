import { React, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { TopBar, SideBar } from "./";
import config from "../../config";

const Content = ({
  children,
  language,
  signout,
  pageWidth,
  _main_,
  main_focus,
  os,
}) => {
  const pathName = useLocation();
  const navigate = useNavigate();
  const [wrapper, setWrapper] = useState(true);
  const sidebar_ref = useRef();

  useEffect(() => {
    if (pathName.pathname === "/") {
      navigate("/hub");
    } else if (config.unwrapped_routes.includes(pathName.pathname)) {
      setWrapper(false);
    } else {
      setWrapper(true);
    }
    document.getElementById("primary_focus").focus();
  }, [pathName]);

  useEffect(() => {
    if (wrapper) resetMenu();
  }, [pageWidth]);

  const toggleMenu = () => {
    if (pageWidth < 900 && sidebar_ref.current.clientWidth === 0) {
      sidebar_ref.current.style.width = "100%";
      sidebar_ref.current.firstChild.style.width = "100%";
    } else {
      sidebar_ref.current.style.width = "0px";
      sidebar_ref.current.firstChild.style.width = "0px";
    }
  };

  const resetMenu = () => {
    if (pageWidth < 900) {
      sidebar_ref.current.style.width = "0px";
      sidebar_ref.current.firstChild.style.width = "0px";
    } else {
      sidebar_ref.current.style.width = "220px";
      sidebar_ref.current.firstChild.style.width = "220px";
    }
  };

  const skipLink = (show = true) => {
    if (show) {
      document.getElementById("skip_link").classList.add("visible");
    } else {
      document.getElementById("skip_link").classList.remove("visible");
    }
  };

  const killFocus = (e) => {
    e.target.setAttribute("tabIndex", -1);
  };

  return (
    <div className="main" ref={_main_}>
      <div
        className="sr-only"
        id="primary_focus"
        tabIndex={0}
        onBlur={(e) => killFocus(e)}
      ></div>
      <div
        id="skip_link"
        className="link sr-only"
        role="link"
        onClick={() => skipLink(false)}
        onKeyUpCapture={(e) => e.key === "Enter" && main_focus.current.focus()}
        onFocus={() => skipLink()}
        onBlur={() => skipLink(false)}
        tabIndex={0}
      >
        {language.labels.aria.skip}
      </div>

      {wrapper ? (
        <>
          <TopBar language={language} toggleMenu={toggleMenu} />
          <SideBar
            language={language}
            signout={signout}
            resetMenu={resetMenu}
            os={os}
            ref={sidebar_ref}
          />
          <div>{children}</div>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default Content;
